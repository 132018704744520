import React from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export default function Intro({onCLick}) {
  return <div className=" pb-20">

   {/* MOBILE */}
        <div className="w-full lg:hidden py-20 ">
            <div className="w-full self-center">
                <img 
                    className="mx-auto"
                    src={require("../../../assets/images/img-iphone.png")}
                    alt=""
                    width="290"
                />
            </div>
            <div className="w-full self-center p-10">
                <p className="text-2xl text-accent text-aileron-bold ">
                    CIERGIO, your community engagement and management app, where you can quickly <br/>“Share-and-Go”!
                </p>
                <div className="divider bg-black my-5"></div>
                <p className="text-lg leading-loose text-gray-800">
                Ciergio makes it easy for organization leaders to streamline coordination and communication amongst groups of people in communities, real-estate villages, organizations, companies, clubs and institutions all through a centralized app.
                </p>
                <div className="mt-10">
                    <a href="#" className="inline-block text-sm px-4 py-4 leading-none text-sm rounded text-white hover:text-white hover:bg-accent mt-4 lg:mt-0 bg-accent">
                        Click here and find out more!
                        </a>
                </div>
            </div>
        </div>
    {/* DESKTOP */}
        <div className="w-full hidden lg:flex py-20 ">

            <div className="w-1/2 self-center pr-10">
                <p className="text-3xl text-accent text-aileron-bold pr-20">
                    CIERGIO, your community engagement and management app, where you can quickly <br/>“Share-and-Go”!
                </p>
                <div className="divider bg-black my-10"></div>
                <p className="text-xl leading-loose text-gray-800 pr-20">
                Ciergio makes it easy for organization leaders to streamline coordination and communication amongst groups of people in communities, real-estate villages, organizations, companies, clubs and institutions all through a centralized app.
                </p>
                <div className="mt-10">
                    <button onClick={()=>{onCLick('feature')}} className="inline-block text-sm px-8 py-4 leading-none text-xl rounded text-white hover:text-white hover:bg-accent mt-4 lg:mt-0 bg-accent">
                        Click here and find out more!
                        </button>
                </div>
            </div>
            <div className="w-1/2 self-center relative">
                <div 
                    className="absolute w-310"
                    style={{
                        backgroundImage:`url(${require("../../../assets/images/img-phone-frame.png")})`,
                        height:714,
                        zIndex:2,
                        backgroundSize: 'cover',
                        backgroundRepeat:'no-repeat',
                        left: "50%",
                        width: 360,
                        top: '-25px',
                        marginLeft:"-186px"
                    }}
                />
                <div className="w-300 mx-auto">
                <Carousel
                    showArrows={false}
                    autoPlay={true}
                    infiniteLoop={true}
                    background={'white'}
                    showStatus={false}
                    showIndicators={false}
                    showThumbs={false}
                    // transitionTime={120}
                    interval={5000}
                >
                    <div>
                        <img 
                            className="mx-auto"
                            src={require("../../../assets/images/img-phone-frame-1.png")}
                            alt=""
                            width="290"
                        />
                        {/* <p className="legend">Legend 1</p> */}
                    </div>
                    <div>
                        <img 
                            className="mx-auto"
                            src={require("../../../assets/images/img-phone-frame-2.png")}
                            alt=""
                            width="290"
                        />
                        {/* <p className="legend">Legend 2</p> */}
                    </div>
                    <div>
                        <img 
                            className="mx-auto"
                            src={require("../../../assets/images/img-phone-frame-3.png")}
                            alt=""
                            width="290"
                        />
                        {/* <p className="legend">Legend 2</p> */}
                    </div>
                </Carousel>
                </div>
            </div>
        </div>
      </div>

}